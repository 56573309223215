export const prepareEditFormDataWithImage = form => {
  const { image, ...rest } = form;
  let formData = new FormData();
  if (typeof image !== "string") {
    formData.append("image", image);
  }
  formData.append("payload", JSON.stringify(rest));

  return formData;
};

export default {
  prepareEditFormDataWithImage
};
