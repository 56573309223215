<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-promotions' }">{{
            $t("MENU.ITEM.PROMOTIONS.PROMOTIONS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['promotions-destroy']"
          ></DeleteButton>
          <button
            @click="onSend"
            class="btn btn-light-info font-weight-bolder mr-2"
          >
            <i class="flaticon-send"></i>{{ $t("BUTTON.SEND") }}
          </button>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingPromotion"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <div :transition="false" :reverse-transition="false">
          <ul class="nav nav-tabs nav-tabs-line">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab == 0 }"
                data-toggle="tab"
                @click="toggleTab(0)"
              >
                Основни данни
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab == 1 }"
                data-toggle="tab"
                @click="toggleTab(1)"
              >
                Продукти ({{ products.length }})
              </a>
            </li>
          </ul>
          <div class="tab-content mt-5">
            <div v-show="activeTab == 0">
              <b-form>
                <PromotionForm ref="Form" :form="form" />
              </b-form>
            </div>
            <div v-show="activeTab == 1">
              <PromotionProducts
                v-if="form.shopId"
                :products="products"
                :shopId="form.shopId"
                :promotionId="id"
                @save="onSaveProducts"
              />
            </div>
          </div>

          <v-overlay :value="!firstLoader && isLoadingPromotion">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </div>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['promotions-destroy']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingPromotion"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_PROMOTION,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  SEND_NOTIFICATIONS
} from "@/modules/promotion/store/promotion.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";
import PromotionForm from "../components/PromotionForm.vue";
import PromotionProducts from "../components/PromotionProducts.vue";
import { prepareEditFormDataWithImage } from "@/core/services/form.service";

export default {
  components: {
    KTCard,
    SaveButtonDropdown,
    PromotionForm,
    PromotionProducts
  },
  data() {
    return {
      errors: {},
      firstLoader: true,
      form: {},
      id: null,
      products: [],
      activeTab: 0
    };
  },
  computed: {
    ...mapGetters(["isLoadingPromotion"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Промоции", route: { name: "list-promotions" } },
      { title: "Редактиране на промоция" }
    ]);
    this.getPromotion();
  },
  methods: {
    getPromotion() {
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_PROMOTION, id)
        .then(data => {
          this.$nextTick(() => {
            this.id = data.data.id;
            this.products = data.data.products;
            this.form = data.data.form;
            this.firstLoader = false;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            this.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-promotions" });
          }
        });
    },
    onSave(next) {
      this.$refs.Form.$v.form.$touch();
      if (this.$refs.Form.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_PROMOTION, {
          id: this.id,
          payload: prepareEditFormDataWithImage(this.form)
        })
        .then(data => {
          this.errors = data.data.errors;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          if (next == "new") {
            this.$router.push({ name: "add-promotion" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-promotions" });
          }
        })
        .catch(response => {
          console.error(response);
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    },
    onDelete() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(DELETE_PROMOTION, this.id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.$router.push({ name: "list-promotions" });
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    },
    onSend() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(SEND_NOTIFICATIONS, this.id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.$router.push({ name: "list-promotions" });
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    },
    toggleTab(val) {
      this.activeTab = val;
    },
    onSaveProducts() {
      this.getPromotion();
    }
  }
};
</script>
