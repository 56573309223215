<template>
  <b-form-select
    value-field="id"
    text-field="name"
    :value="value"
    :options="options"
    multiple
    @input="handleInput"
  ></b-form-select>
</template>

<script>
export default {
  name: "FormMultiSelect",
  inheritAttrs: false,
  props: {
    /**
     * Selected options
     */
    value: {
      type: Array,
      default: function() {
        return [];
      }
    },
    /**
     * All options existing in the select options
     */
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    /**
     * Label
     */
    label: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
