<template>
  <b-dropdown
    @click="
      $emit(
        'save',
        defaultAction && defaultAction != '' ? defaultAction : 'exit'
      )
    "
    variant="success"
    right
    split
    text="Save"
    ref="button_dropdown_save"
    :disabled="isLoading"
  >
    <template v-slot:button-content>
      <div :class="isLoading ? 'spinner spinner-right' : ''">
        <span :class="isLoading ? 'mr-15' : ''">
          {{
            defaultAction === "new"
              ? $t("BUTTON.SAVE_AND_ADD_NEW")
              : defaultAction === "copy"
              ? $t("BUTTON.SAVE_AND_COPY")
              : $t("BUTTON.SAVE")
          }}
        </span>
      </div>
    </template>
    <b-dropdown-item>
      <span @click="$emit('save', 'continue')" class="nav-text"
        ><i class="nav-icon flaticon2-reload"></i>
        {{ $t("BUTTON.SAVE_AND_CONTINUE") }}</span
      >
    </b-dropdown-item>
    <b-dropdown-item v-if="defaultAction !== 'new'">
      <span @click="$emit('save', 'new')" class="nav-text"
        ><i class="nav-icon flaticon2-add-1"></i>
        {{ $t("BUTTON.SAVE_AND_ADD_NEW") }}</span
      >
    </b-dropdown-item>
    <b-dropdown-item v-if="defaultAction !== 'copy' && withCopy">
      <span @click="$emit('save', 'copy')" class="nav-text"
        ><i class="nav-icon flaticon2-copy"></i>
        {{ $t("BUTTON.SAVE_AND_COPY") }}</span
      >
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item
      ><i class="nav-icon flaticon2-power"></i>
      <span @click="$emit('save', 'exit')" class="nav-text"
        ><i class="fas fa-sign-out-alt"></i>
        {{ $t("BUTTON.SAVE_AND_EXIT") }}</span
      >
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
export default {
  name: "SaveButtonDropdown",
  inheritAttrs: false,
  props: {
    /**
     * Loader Spinner
     */
    isLoading: Boolean,
    /**
     * Default action
     * e.g. next, continue, exit
     */
    defaultAction: String,
    withCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
