<template>
  <v-dialog
    v-model="showModal"
    style="z-index: 2001 !important;"
    max-width="500px"
  >
    <v-card class="p-4">
      <v-card-title>
        <span v-if="isEdit">
          Редакция на продукт
        </span>
        <span v-else>
          Добавяне на нов продукт
        </span>
      </v-card-title>
      <v-card-text>
        <b-form-row v-if="form">
          <b-col md="12">
            <b-form-group
              v-bind:id="'product-input'"
              v-bind:label="$t('FORM_LABELS.SELECT_PRODUCT')"
              v-bind:label-for="'product-input'"
            >
              <FormSelect
                id="productId-input"
                :placeholder="$t('FORM_LABELS.PRODUCT')"
                type="select"
                name="productId"
                :options="products"
                clearable
                v-model="form.productId"
                @input="onProductInput"
              ></FormSelect>
            </b-form-group>
          </b-col>
          <b-col v-if="form.productId" :md="12">
            <b-label class="ml-0 pl-0">
              {{ $t("FORM_LABELS.UPDATE_PRODUCT") }}
              <FormSwitch
                id="active"
                type="checkbox"
                name="active"
                v-model="form.updateProduct"
              ></FormSwitch>
            </b-label>
          </b-col>
          <b-col v-show="showProductFields" md="12">
            <b-form-group
              v-bind:id="'image-input'"
              v-bind:label="$t('FORM_LABELS.IMAGE')"
              v-bind:label-for="'image-input'"
            >
              <div class="input-group">
                <FormFile name="image" image v-model="form.image"> </FormFile>
              </div>
            </b-form-group>
          </b-col>

          <b-col v-show="showProductFields" md="12">
            <b-form-group
              v-bind:id="'name-input'"
              v-bind:label="$t('FORM_LABELS.NAME')"
              v-bind:label-for="'name-input'"
            >
              <div class="input-group">
                <FormInput
                  id="name-input"
                  type="text"
                  name="name"
                  v-model="form.name"
                >
                </FormInput>
              </div>
            </b-form-group>
          </b-col>

          <b-col v-show="showProductFields" md="12">
            <b-form-group
              v-bind:id="'url-input'"
              v-bind:label="$t('FORM_LABELS.URL')"
              v-bind:label-for="'url-input'"
            >
              <div class="input-group">
                <FormInput
                  id="url-input"
                  type="text"
                  name="url"
                  v-model="form.url"
                >
                </FormInput>
              </div>
            </b-form-group>
          </b-col>

          <b-col v-show="showProductFields" md="12">
            <b-form-group
              v-bind:id="'description-input'"
              v-bind:label="$t('FORM_LABELS.DESCRIPTION')"
              v-bind:label-for="'description-input'"
            >
              <FormTextarea
                id="description-input"
                name="description"
                v-model="form.description"
              >
              </FormTextarea>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              v-bind:id="'price-input'"
              v-bind:label="$t('FORM_LABELS.PRICE')"
              v-bind:label-for="'price-input'"
            >
              <div class="input-group">
                <FormInput
                  id="price-input"
                  type="number"
                  name="price"
                  v-model="form.price"
                >
                </FormInput>
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              v-bind:id="'promoPrice-input'"
              v-bind:label="$t('FORM_LABELS.PROMO_PRICE')"
              v-bind:label-for="'promoPrice-input'"
            >
              <div class="input-group">
                <FormInput
                  id="promoPrice-input"
                  type="number"
                  name="promoPrice"
                  v-model="form.promoPrice"
                >
                </FormInput>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <b-button size="md" variant="danger" @click="closeModal">
          Затвори
        </b-button>
        <v-spacer></v-spacer>
        <b-button
          size="md"
          variant="success"
          :disabled="loading"
          :loading="loading"
          @click="save()"
        >
          Запиши
        </b-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormTextarea from "@/view/content/forms/components/FormTextarea.vue";
import FormFile from "@/view/content/forms/components/FormFile.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch";
import FormSelect from "@/view/content/forms/components/FormSelect";

export default {
  name: "PromotionProductModal",
  components: {
    FormInput,
    FormTextarea,
    FormFile,
    FormSwitch,
    FormSelect
  },
  props: {
    form: Object,
    errors: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array
    }
  },
  data() {
    return {
      showModal: false,
      loading: false
    };
  },
  computed: {
    showProductFields() {
      return !this.form.productId || this.form.updateProduct;
    }
  },
  methods: {
    show() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    save() {
      this.$emit("save", this.form);
    },
    onProductInput(id) {
      const product = this.products.find(x => x.id === id);

      this.form.url = product.url;
      this.form.description = product.description;
      this.form.image = product.image;
      this.form.imageThumbnail = product.imageThumbnail;
      this.form.name = product.name;
    }
  }
};
</script>

<style type="text/css">
.modal-dialog {
  min-height: 500px !important;
}
</style>
