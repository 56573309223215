<template>
  <div>
    <span class="switch switch-outline switch-icon switch-success">
      <label class="flex-label">
        <span v-if="label" class="mr-2" v-html="label"> </span>
        <input
          :value="value"
          @change="setChecked"
          :checked="isChecked"
          v-bind="$attrs"
        />
        <span></span>
      </label>
    </span>
  </div>
</template>

<script>
export default {
  name: "FormSwitch",
  inheritAttrs: false,
  props: {
    /**
     * V-Model
     */
    value: {},
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    isChecked() {
      return !!this.value;
    }
  },
  methods: {
    setChecked($event) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", $event.target.checked ? 1 : 0);
    }
  }
};
</script>

<style scoped>
.flex-label {
  align-items: center;
  display: flex;
}
</style>
