<template>
  <div class="w-100">
    <b-input-group>
      <span
        v-if="removable && imageSrc"
        class="btn btn-light-danger pr-2 rounded-right-0"
        @click="handleDelete"
      >
        <i class="fa fa-times"></i>
      </span>
      <b-form-file
        :value="value"
        v-bind="$attrs"
        placeholder="Изпберете файл или го пуснете тук..."
        drop-placeholder="Пуснете файла тук..."
        :accept="image ? 'image/jpeg, image/png, image/gif, image/webp' : accept"
        :state="validateState"
        @input="setValue"
      ></b-form-file>

      <slot name="button-append"></slot>
      <b-form-invalid-feedback
        v-if="i18n.validation"
        :id="'invalid-' + feedback_id"
      >
        {{ $t(i18n.validation, validations.params) }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else :id="'invalid-' + feedback_id">
        {{ $t("VALIDATION.INVALID", { name: "" }) }}
      </b-form-invalid-feedback>
      <b-form-valid-feedback :id="'valid-' + feedback_id">
        {{ $t("VALIDATION.VALID") }}
      </b-form-valid-feedback>
    </b-input-group>
    <div class="d-flex flex-center text-center mt-5">
      <v-img v-if="imageSrc" max-height="200" contain :src="imageSrc"> </v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  inheritAttrs: false,
  props: {
    /**
     * name of the input
     */
    name: {
      type: String,
      default: ""
    },
    /**
     * form helper description
     */
    description: {
      type: String,
      default: ""
    },
    /**
     * All Translation i18n keys used
     */
    feedback_id: {
      type: String,
      default: ""
    },
    /**
     * All Translation i18n keys used
     */
    i18n: {
      type: Object,
      default: () => ({})
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: () => ({})
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: null
    },
    /**
     * V-Model
     */
    value: {},
    accept: {
      type: String,
      default: null
    },
    image: {
      type: Boolean,
      default: null
    },
    removable: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    imageSrc() {
      return this.image
        ? this.value && typeof this.value !== "string"
          ? URL.createObjectURL(this.value)
          : this.value
        : null;
    }
  },
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    },
    handleDelete() {
      this.$emit("input", null);
    }
  }
};
</script>
