<template>
  <div>
    <button
      v-if="checkIfUserHasPermission(['products-store'])"
      @click="onClickAdd"
      class="btn btn-light-info font-weight-bolder mr-2 mb-5"
    >
      <i class="flaticon-plus"></i>{{ $t("BUTTON.ADD") }}
    </button>
    <v-data-table :headers="headers" :items="products" class="px-6 elevation-1">
      <template v-slot:[`item.imageThumbnail`]="{ item }">
        <v-img :src="item.imageThumbnail" width="100"></v-img>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <span v-if="checkIfUserHasPermission(['products-update'])">
            <v-btn class="mr-2" fab dark x-small @click="onEditItem(item)">
              <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
          </span>
          <template v-else>Няма права</template>
          <span v-permission="['products-destroy']">
            <v-btn
              class="mr-2"
              color="error"
              fab
              x-small
              @click="onDeleteItem(item.id)"
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </span>
        </div>
      </template>
    </v-data-table>
    <PromotionProductModal
      ref="PromotionProductModal"
      :form="selectedProduct"
      :is-edit="!!selectedId"
      :errors="errors"
      :products="productOptions"
      @save="handleProductSave"
    ></PromotionProductModal>
  </div>
</template>

<script>
import {
  DELETE_PRODUCT_PROMOTION,
  SAVE_PRODUCT_PROMOTION,
  UPDATE_PRODUCT_PROMOTION
} from "@/modules/product-promotion/store/product-promotion.module";
import PromotionProductModal from "./PromotionProductModal.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin";
import { prepareEditFormDataWithImage } from "@/core/services/form.service";
import { FETCH_PRODUCTS } from "@/modules/product/store/product.module";

export default {
  mixins: [permissionMixin],
  components: { PromotionProductModal },
  props: {
    products: Array,
    shopId: Number,
    promotionId: Number
  },
  data() {
    return {
      selectedId: null,
      selectedProduct: {},
      errors: {},
      showDrawer: false,
      productOptions: []
    };
  },
  computed: {
    headers() {
      return [
        { text: "Снимка", value: "imageThumbnail" },
        { text: "Име", value: "name" },
        { text: "Линк", value: "url" },
        { text: "Описание", value: "description" },
        { text: "Цена", value: "price" },
        { text: "Промо цена", value: "promoPrice" },
        {
          align: "right",
          text: "Опции",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ];
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$store
        .dispatch(
          FETCH_PRODUCTS,
          this.$url.transformParams({
            shopId: this.shopId
          })
        )
        .then(({ data }) => {
          this.productOptions = data.data;
        });
    },
    onClickAdd() {
      this.selectedId = null;
      this.selectedProduct = {
        productId: null,
        shopId: this.shopId,
        promotionId: this.promotionId,
        updateProduct: false,
        name: null,
        url: null,
        description: null,
        price: null,
        promoPrice: null
      };
      this.$refs.PromotionProductModal.show();
    },
    handleProductSave(form) {
      if (this.selectedId) {
        this.updateProduct(form);
      } else {
        this.createProduct(form);
      }
    },
    updateProduct(form) {
      this.loading = true;
      this.$store
        .dispatch(UPDATE_PRODUCT_PROMOTION, {
          id: this.selectedId,
          payload: prepareEditFormDataWithImage({
            shopId: this.shopId,
            promotionId: this.promotionId,
            ...form
          })
        })
        .then(data => {
          this.$refs.PromotionProductModal.closeModal();
          this.errors = data.data.errors;

          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          this.getProducts();
          this.$emit("save");
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createProduct(form) {
      this.loading = true;

      if (form.productId) {
        const { image, ...rest } = form;

        if (typeof image === "string") {
          form = rest;
        }
      }

      this.$store
        .dispatch(SAVE_PRODUCT_PROMOTION, {
          shopId: this.shopId,
          promotionId: this.promotionId,
          ...form
        })
        .then(data => {
          this.$refs.PromotionProductModal.closeModal();
          this.errors = data.data.errors;

          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          this.getProducts();
          this.$emit("save");
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEditItem(item) {
      this.selectedProduct = JSON.parse(JSON.stringify(item));
      this.selectedId = this.selectedProduct.id;
      this.$refs.PromotionProductModal.show();
    },
    onDeleteItem(id) {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(DELETE_PRODUCT_PROMOTION, id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.getProducts();
              this.$emit("save");
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>
